// export const DELETE_LIBRARY_CHILD_ERROR = 70002; //"删除失败，请确保文件夹中不存在别的文件夹或文本库，以免误删"
// export const DELETE_LIBRARY_ANALYSIS_ERROR = 70003; //删除失败，请确保要删除的文本库没有引用的分析，以免误删
// export const DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR = 70004; //删除失败，请确保要删除的文件夹中不存在别的文件夹或文本库且文本库没有引用的分析，以免误删

// export const CREATE_NAME_DUPLICATE_ERROR = 60002; // 名称重复

//自定义错误
export const CUSTOM_MSG = 10000; //, ""),
//权限不足
export const NO_PERMISSION = 10010; // "无权限查看此资源"),

//Token验证
export const TOKEN_WITHOUT = 10001; // "登录超时,请重新登录"),
export const TOKEN_INVALID = 10002; // "登录超时,请重新登录"),
export const USER_NOT_LOGIN = 10003; // "用户未登录"),
export const USER_OTHER_LOGIN = 10004; // "账号已在别处登录"),
export const HASHMAP_CONVERT_WRONG = 10005; // "MAP转对象错误"),
export const ORG_NOT_LOGIN = 10006; // "机构未登录"),
export const TOKEN_WITHOUT_ORG = 10006; // "机构登录失效"),
export const USER_IP_NOT_IN_ORG = 10006; // "当前用户ip已不在机构范围内"),
export const USER_IP_ORG_CHANGE = 10006; // "当前用户ip归属机构已改变"),

//用户相//
export const EMAIL_HAVE_BEEN_USED = 20001; // "邮箱已被占用"),
export const PHONE_HAVE_BEEN_USED = 20002; // "手机已被占用"),
export const NAME_HAVE_BEEN_USED = 20003; // "昵称已被占用"),
export const SMS_CODE_SEND_FAIL = 20004; // "发送短信验证码失败"),
export const SMS_CODE_FAILURE = 20005; // "验证码已过期"),
export const SMS_CODE_WRONG = 20006; // "验证码错误"),
export const USER_LOGIN_FAIL = 20007; // "密码错误"),
export const EMAIL_SEND_FAIL = 20008; // "邮件发送失败"),
export const OLD_PASSWORD_WRONG = 20009; // "旧密码错误"),
export const USER_HAS_LOGIN = 20010; // "该账号已在别处登录，请退出重新登录！"),

//错误相关
export const DATA_ERROR = 30001; // "数据操作失败"),
export const PARAM_VALID_ERROR = 30002; //, "请求参数校验失败"),
export const USER_ROLE_LEVEL_NOT_ENOUGH = 30003; //, "用户账号角色等级不足"),
export const IMPORT_EXCEL_TABLE_TITLE_ERROR = 30005; //, "导入Excel时表头校验失败"),
//余额
export const AMOUNT_NOT_ARRIVE = 40001; // "余额不足"),

//授权失败
export const NO_AUTH = 50001; // "操作失败，无权访问，请联系客服！"),
//无文本数据
export const NO_DATA = 50002; // "分析失败，所选文本字段无文本数据！"),
//查询条件有误
export const SEARCH_CONDITION_ERROR = 50003; // "查询条件有误"),

export const ERROR_CODE_NAME_DUPLICATE = 60002; // "名称重复"),
export const ERROR_CODE_LANGUAGE_ERROR = 60005; // "语言错误"),
export const ERROR_CODE_PHONETIC_ALPHABET_TRANSFORMATION_ERROR = 60003; // "拼音转换失败"),

export const ANALYSIS_ERROR = 70001; // "分析失败"),
export const DELETE_LIBRARY_CHILD_ERROR = 70002; // "删除失败，请确保文件夹中不存在别的文件夹或文本库/分析，以免误删"),
export const DELETE_LIBRARY_ANALYSIS_ERROR = 70003; // "删除失败，请确保要删除的文本库没有引用的分析，以免误删"),
export const DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR = 70004; // "删除失败，请确保要删除的文件夹中不存在别的文件夹或文本库/分析且文本库没有引用的分析，以免误删"),

// MongoDB查询条件转换失败
export const CONDITION_PARSE_FAILED = 90001; // "MongoDB查询条件识别失败：错误的操作符或数据格式");
