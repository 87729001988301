/** axios封装的接口请求方法 */
import axios from "axios";
import VueCookies from "vue-cookies";
import { ElMessage } from "element-plus";
import { remindErrorMessage } from "../utils/utils";
import router from "@/router";
import store from "@/store";
import {
  DATA_ERROR,
  AMOUNT_NOT_ARRIVE,
  USER_ROLE_LEVEL_NOT_ENOUGH
} from "@/constant/error_code";

let qs = require("qs");

axios.defaults.withCredentials = true;

/**开发环境 */
// let baseUrl = `http://192.168.1.172:8100/ringnlp/`;
let baseUrl = `https://wx.ringdata.com/newringnlp/`;
// let baseAuthUrl = `http://192.168.1.172:8065/auth/`; //175
// let baseAuthUrl = `http://175.102.15.229:8065/auth/`; //175
let baseAuthUrl = `https://i.ringdata.com/uums`; //阿里云正式
export let socketUrl = `wss://wx.ringdata.com/ws`;

/**
 * post 请求
 */
const httpPost = async function (url = "", data = {}, contentType, rType) {
  // 判断使用的url
  // let tempUrl = baseUrl;
  let tempUrl = url.indexOf("centerAuth") != -1 ? baseAuthUrl : baseUrl;

  const instance = axios.create({
    baseURL: tempUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json;charset=UTF-8",
      "Content-type": contentType ? contentType : "application/json",
      Authorization: VueCookies.get("tokenWeb")
        ? "Bearer " + VueCookies.get("tokenWeb")
        : ""
    },
    responseType: rType
  });
  return instance
    .post(url, data)
    .then((result) => {
      var res = result.data;
      if (res && res.code === 0) {
        return Promise.resolve(res);
      } else {
        if (res.code === 15000) {
          remindErrorMessage("网络请求异常，请稍后再试或联系客服！");
          return res;
        } else if (res.code === 10000) {
          ElMessage({
            message: res.message,
            type: "warning"
          });
          return res;
        } else if (res.code === 10001) {
          // store.commit("setLoginStatus", {
          //   status: false
          // });
          if (res.data) {
            remindErrorMessage("登录信息已失效,请重新登录");
            VueCookies.remove("tokenWeb");
            VueCookies.remove("userInfo");
            VueCookies.remove("tokenWebRefresh");
            VueCookies.remove("tokenWeb", "/", "ringdata.com");
            VueCookies.remove("userInfo", "/", "ringdata.com");
            VueCookies.remove("tokenWebRefresh", "/", "ringdata.com");
          } else {
            remindErrorMessage("登录信息已失效,请重新登录");
          }
          setTimeout(() => {
            router.push("/login");
          }, 1000);

          return res;
        } else if (res.code === 10010) {
          // 无权限
          remindErrorMessage(res.message);

          // window.location.href = window.location.origin + "/no-auth";
        } else if (
          res.code === USER_ROLE_LEVEL_NOT_ENOUGH ||
          res.code == AMOUNT_NOT_ARRIVE
        ) {
          remindErrorMessage(res.message);
          return res;
        }
        return Promise.resolve(res);
      }
    })
    .catch((error) => {
      let res = error.response;
      if (res.status === 400 || res.status === 401) {
        // store.commit("setLoginStatus", {
        //   status: false
        // });
        if (res.data) {
          remindErrorMessage("登录信息已失效,请重新登录");
          VueCookies.remove("tokenWeb");
          VueCookies.remove("userInfo");
          VueCookies.remove("tokenWebRefresh");
          VueCookies.remove("tokenWeb", "/", "ringdata.com");
          VueCookies.remove("userInfo", "/", "ringdata.com");
          VueCookies.remove("tokenWebRefresh", "/", "ringdata.com");
        } else {
          remindErrorMessage("登录信息已失效,请重新登录");
        }
        setTimeout(() => {
          router.push("/login");
        }, 1000);
        return res;
      }
      res = {
        code: -1,
        message: "网络请求异常，请稍后再试或联系客服！"
      };
      console.log("网络请求错误");
      return res;
    });
};

/**
 * 认证请求
 */
const httpPostToken = async function (url = "", data) {
  const instance = axios.create({
    baseURL: baseAuthUrl,
    withCredentials: false,
    headers: {
      Authorization: "Basic bW9uZXR3YXJlOm1vbmV0d2FyZTIwMTkuQHNoLmNvbQ==",
      "content-type": "application/x-www-form-urlencoded;charset=utf-8"
    }
  });
  return instance
    .post(url, data)
    .then((result) => {
      var res = result.data;
      if (result && result.status === 200) {
        if (res != null && res.access_token != null && res.access_token != "") {
          VueCookies.set(
            "tokenWeb",
            res.access_token,
            24 * 60 * 60,
            "/",
            "ringdata.com",
            true,
            "None"
          );
          VueCookies.set(
            "userInfo",
            JSON.stringify(res.userInfo),
            24 * 60 * 60,
            "/",
            "ringdata.com",
            true,
            "None"
          );
          VueCookies.set(
            "tokenWebRefresh",
            res.refresh_token,
            47 * 60 * 60,
            "/",
            "ringdata.com",
            true,
            "None"
          );
          res = {
            code: 0,
            message: "登录成功"
          };
          return Promise.resolve(res);
        } else {
          res = {
            code: -1,
            message: "认证失败，请稍后再试或联系客服！"
          };
          return res;
        }
      }
    })
    .catch((error) => {
      let res = error.response;
      if (res.status === 400 || res.status === 401 || res.status === 500) {
        res = {
          code: res.data.code,
          message: res.data.message
        };
        ElMessage({
          message: res.message,
          type: "warning"
        });
      } else {
        res = {
          code: -1,
          message: "网络请求异常，请稍后再试或联系客服！"
        };
        remindErrorMessage("网络请求异常，请稍后再试或联系客服！");
      }

      return res;
    });
};
/**
 * post 请求 uums
 */
const httpPostIsAnonymous = async function (url = "", data = {}) {
  const instance = axios.create({
    baseURL: baseAuthUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json;charset=UTF-8",
      "content-type": "application/json"
    }
  });
  return instance
    .post(url, JSON.stringify(data))
    .then((result) => {
      var res = result.data;
      if (res && res.code === 0) {
        return Promise.resolve(res);
      } else {
        if (res.code === 15000) {
          remindErrorMessage("网络请求异常，请稍后再试或联系客服！");
          return res;
        }
        return Promise.resolve(res);
      }
    })
    .catch(() => {
      let res = {
        code: -1,
        message: "网络请求异常，请稍后再试或联系客服！"
      };
      console.log("网络请求错误");
      return res;
    });
};
export {
  // homeUrl,
  baseUrl,
  baseAuthUrl,
  httpPost,
  httpPostToken,
  httpPostIsAnonymous
};
