import { ElMessage } from "element-plus";

/**错误提示 */
const remindErrorMessage = (message) => {
  if (!sessionStorage.getItem("wx_canRemindErrorMessage")) {
    sessionStorage.setItem("wx_canRemindErrorMessage", 1);
    ElMessage({
      message: message,
      type: "warning"
    });
    setTimeout(() => {
      sessionStorage.removeItem("wx_canRemindErrorMessage");
    }, 2000);
  }
};

/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

export { remindErrorMessage, Debounce };
